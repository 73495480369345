import React, { useState } from "react";
import { useEffect } from "react";
import { Router, useHistory } from "react-router-dom";
import { getMetalPrices } from "../../Services/API/priceServices";
import "./Form.css";
import TextField from "@mui/material/TextField";
import { Spinner } from "react-bootstrap";
const metals = [
  {
    name: 'Gold',
    metalCode: 'Au',
    pricesToshow: [100, 500, 1000, 5000],
    gramsToshow: [1, 10, 50, 100],
  },
  {
    name: 'Silver',
    metalCode: 'Ag',
    pricesToshow: [100, 500, 1000, 5000],
    gramsToshow: [1, 10, 50, 100],
  },
  {
    name: 'Platinum',
    metalCode: 'Pt',
    pricesToshow: [100, 500, 1000, 5000],
    gramsToshow: [1, 10, 50, 100],
  },
  {
    name: 'Palladium',
    metalCode: 'Pd',
    pricesToshow: [100, 500, 1000, 5000],
    gramsToshow: [1, 10, 50, 100],
  },
];

function Form() {
  const [SelectedMetal, setSelectedMetal] = useState(metals[0]);
  const [buyMethod, setBuyMethod] = useState('Rupees');
  const [action, setAction] = useState('Buy');
  const [price, setPrice] = useState(1000);
  // const [totalPrice, setTotalPrice] = useState(0.0);
  const [calCulatedPrice, setCalculatedPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [priceByGrams, setPriceByGrams] = useState("-");
  const history = useHistory();

  const tabs = ['Buy', 'Sell'];

  useEffect(async () => {
    const params = {
      metalCode: SelectedMetal?.metalCode,
      partnerId: 1,
      type: buyMethod === 'Rupees' ? 'AMOUNT' : 'QTY',
      uom: 'g',
      value: price || 0,
      currency: 'INR',
    };
    const priceData = await getMetalPrices(params);
    if (action === 'Buy') {
      if (buyMethod === 'Rupees') {
        setCalculatedPrice(
          priceData?.data?.summary?.ask?.requested?.totalQuantity || '-'
        );
        return;
      }
      if (buyMethod === 'Grams') {
        setCalculatedPrice(
          priceData?.data?.summary?.ask?.requested?.totalAmount || '-'
        );
        return;
      }
    }
    if (action === 'Sell') {
      if (buyMethod === 'Rupees')
        setCalculatedPrice(
          priceData?.data?.summary?.bid?.requested?.totalQuantity || '-'
        );
      if (buyMethod === 'Grams')
        setCalculatedPrice(
          priceData?.data?.summary?.bid?.requested?.totalAmount || '-'
        );
      return;
    }
    setCalculatedPrice('-');
  }, [price, SelectedMetal, action]);

  const fetchPrice = async () => {
    const params = {
      metalCode: SelectedMetal?.metalCode,
      partnerId: 1,
      type: 'QTY',
      uom: 'g',
      value: 1,
      currency: 'INR',
    };
    setLoading(true);
    const priceData = await getMetalPrices(params);
    if (
      action === 'Buy' &&
      priceData?.data?.summary?.ask?.requested?.totalAmount
    ) {
      setPriceByGrams(priceData?.data?.summary?.ask?.requested?.totalAmount);
      setLoading(false);
      return;
    }
    if (
      action === 'Sell' &&
      priceData?.data?.summary?.bid?.requested?.totalAmount
    ) {
      setPriceByGrams(priceData?.data?.summary?.bid?.requested?.totalAmount);
      setLoading(false);
      return;
    }
    setPriceByGrams('-');
    setLoading(false);
  };

  useEffect(() => {
    fetchPrice();
    const interval = setInterval(fetchPrice, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [SelectedMetal, buyMethod, action]);

  const redirectMainFunc = () => {
    window.open('https://coinbazaar.ebullion.in/', '_blank');
  };
  return (
    <section className='hero-banner ' data-aos='fade-left'>
      <div className='live-price__container container'>
        <div className='d-flex metals-container justify-content-between'>
          {metals?.map((metal) => {
            return (
              <div
                className='metal-box'
                onClick={() => setSelectedMetal(metal)}
                style={{
                  color:
                    metal?.name === SelectedMetal?.name ? '#301250' : 'white',
                  borderBottom:
                    metal?.name === SelectedMetal?.name
                      ? '4px solid #301250'
                      : '',
                }}
              >
                <div className='mb-3'>{metal?.name} </div>
              </div>
            );
          })}
        </div>

        <div className='live-price text-center'>
          {(loading && !priceByGrams) || priceByGrams == undefined
            ? //  <Spinner />
              '₹0.00/g'
            : `₹${priceByGrams?.toLocaleString('en-GB', {
                minimumFractionDigits: 2,
              })}/g`}
        </div>
        <div className='d-flex justify-content-center align-items-center live-rate__indicator'>
          <div className='live-dot__indicator'></div>
          <div className='live-rate__text'>Live Rates</div>
        </div>
        {action === 'Buy' && (
          <div className='gst-text text-center'>
            Additional 3% GST applicable
      </div>
        )}
      </div>
      <div className='buy-form'>
        <div className='d-flex justify-content-between'>
          {tabs?.map((item) => {
            return (
              <div
                className='buy-form__tab'
                style={{
                  width: 100 / tabs?.length + '%',
                  backgroundColor: action === item ? '#230346' : '#CEA538',
                  color: 'white',
                }}
                onClick={() => {
                  setAction(item);
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
        <div className='buy-now-form'>
          <label
            className='input-label text-center w-100'
            style={{
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '28px',
            }}
          >
            Please enter the {buyMethod === 'Rupees' ? 'amount' : 'quantity'}
          </label>
          <div
            className='d-flex justify-content-between py-3 align-items-center'
            style={{
              gap: '10px',
            }}
          >
            <TextField
              id='outlined-basic'
              label={buyMethod === 'Rupees' ? 'Rupees' : 'Grams'}
              variant='outlined'
              placeholder={buyMethod === 'Rupees' ? '₹' : 'g'}
              value={price}
              inputProps={{
                type: 'number',
                // pattern: "[0-9]{6}",
              }}
              maxLength={6}
              onChange={(e) => {
                if (e.target.value > 999999 || e.target.value < 0) return;
                setPrice(e.target.value);
              }}
            />
            <img
              src='images/exchange-arrow-icon.png'
              width='20px'
              height='15px'
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                if (buyMethod === 'Rupees') {
                  setPrice(10);
                  setBuyMethod('Grams');
                } else {
                  setPrice(1000);
                  setBuyMethod('Rupees');
                }
              }}
            />
            <TextField
              id='outlined-basic'
              label={buyMethod === 'Rupees' ? 'Grams' : 'Rupees'}
              variant='outlined'
              placeholder={buyMethod === 'Rupees' ? 'Amount' : 'Grams'}
              value={calCulatedPrice?.toLocaleString('en-GB', {
                minimumFractionDigits: buyMethod === 'Rupees' ? 4 : 2,
              })}
              inputMode='numeric'
              className='buy-form-input'
              disabled
            />
          </div>
          <div className='d-flex justify-content-between sug-price__container'>
            {buyMethod === 'Rupees'
              ? SelectedMetal?.pricesToshow?.map((price) => (
                  <div className='sug-price' onClick={() => setPrice(price)}>
                    ₹{price}
                  </div>
                ))
              : SelectedMetal?.gramsToshow?.map((gram) => (
                  <div className='sug-price' onClick={() => setPrice(gram)}>
                    {gram}g
                  </div>
                ))}
          </div>
          <div className='d-flex justify-content-center'>
            <button
              className='buy-now-btn mx-auto'
              onClick={() => {
                redirectMainFunc();
              }}
            >
              {action}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;
