import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar, NavDropdown } from 'react-bootstrap';

const TopBands = (props) => {
  const history = useHistory();
  const liStyle = {
    cursor: 'pointer',
  };
  return (
    // <nav className='navbar navbar-expand-lg ' id='main-menu'>
    //   <div className='container'>
    //     <a className='navbar-brand' href='/'>
    //       <img src='images/coin-bazzar1.png' width='177px' height='45' />
    //     </a>
    //     <button
    //       className='navbar-toggler'
    //       type='button'
    //       data-toggle='collapse'
    //       data-target='#navbarResponsive'
    //       aria-controls='navbarResponsive'
    //       aria-expanded='false'
    //       aria-label='Toggle navigation'
    //     >
    //       <span className='navbar-toggler-icon'></span>
    //     </button>
    //     <div className='collapse navbar-collapse' id='navbarResponsive'>
    //       <ul className='navbar-nav ml-auto main-menu-cl'>
    //         {/* <li
    //           className={`nav-item nav-link ${
    //             props.page == 'Main' ? 'active' : ''
    //           }`}
    //           onClick={() => history.push('/')}
    //           style={liStyle}
    //         >
    //           Home
    //           {props.page == 'Main' && (
    //             <span className='sr-only'>(current)</span>
    //           )}

    //         </li> */}

    //         <li className='nav-item'>
    //           <a
    //             className='nav-link last-btn'
    //             onClick={() => history.push('/coming-soon')}
    //             style={liStyle}
    //           >
    //             Sign In / Sign-Up
    //           </a>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
    <Navbar expand='lg' id='main-menu' sticky='top' variant='dark'>
      <div className='container'>
        <a className='navbar-brand' href='/'>
          <img src='images/coin-bazzar1.png' width='177px' height='45' />
        </a>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse>
          <ul className='navbar-nav ml-auto main-menu-cl'>
            <li
              className={`nav-item  ${props.page == 'Main' ? 'active' : ''}`}
              style={{ cursor: 'pointer', color: 'CEA538' }}
            >
              <a href='/' className='nav-link'>
                Home
              </a>
            </li>
            <li
              className={`nav-item  ${props.page == 'faqs' ? 'active' : ''}`}
              style={{ cursor: 'pointer', color: 'CEA538' }}
            >
              <a href='/faq' className='nav-link'>
                FAQs
              </a>
            </li>
            <li
              className={`nav-item nav-link ${
                props.page == 'delivery' ? 'active' : ''
              }`}
              onClick={() =>
                window.open('https://www.coinbazaar.in/', '_blank')
              }
              style={{ cursor: 'pointer', color: 'CEA538' }}
            >
              Buy Physical Coin/ Bar/ Bullion
            </li>
            <li
              className={`nav-item  ${props.page == 'price-charts' ? 'active' : ''}`}
              style={{ cursor: 'pointer', color: 'CEA538' }}
            >
              <a href='/price-charts' className='nav-link'>
                Price Charts
              </a>
            </li>
            {/* <li className='nav-item'> */}
            <li
              className={`nav-item nav-link `}
              onClick={() =>
                window.open('https://coinbazaar.ebullion.in/', '_blank')
              }
              style={{ cursor: 'pointer', color: 'CEA538' }}
            >
              {/* <a
                href='https://coinbazaar-staging.ebullion.in/'
                className='nav-link last-btn'
                style={liStyle}
              > */}
              <span className='last-btn'>Sign In / Sign Up</span>
              {/* </a> */}
            </li>
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default TopBands;
