import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';

const randomize = (data) => {
  return data.map((item) => {
    const random = Math.random() * (3.0 - 1.0) + 1.0;
    return {
      ...item,
      unit_price: (parseFloat(item.unit_price) + random).toFixed(2),
    };
  });
};

const TopBands = () => {
  const [metalFeedData, setmetalFeedData] = useState([]);
  const [currencyFeedData, setcurrencyFeedData] = useState([]);
  const [type, settype] = useState('$');

  // Metal Feed Socket Connection
  // useEffect(() => {

  //     const metalSocket = socketIOClient("wss://dev-api.ebullion.in/metal-pricing");
  //     const currencySocket = socketIOClient("wss://dev-api.ebullion.in/currency-pricing");
  //     let metalData;
  //     let currencyData;

  //     //socket listing on currency-feed-update
  //     metalSocket.on("updated-price", (data) => {

  //         metalData = JSON.parse(data);
  //         // console.log(metalData.data);
  //         setmetalFeedData(metalData.data)

  //     });

  //     //socket listing on currency-feed-update
  //     currencySocket.on("currency-feed-update", (data) => {

  //         currencyData = JSON.parse(data);
  //         // console.log(currencyData);
  //         setcurrencyFeedData(currencyData.data);
  //     });

  //     return () => {
  //     metalSocket.disconnect();
  //     currencySocket.disconnect();
  //     };

  // },[]);

  const convert = () => {
    let sorted = ['Ag', 'Pt', 'Au', 'Pd'];
    let data = [];
    if (metalFeedData.length > 0) {
      sorted.forEach((item) => {
        const metal = metalFeedData.find((i) => i.metalCode == item);
        if (metal) {
          data.push({ item_name: metal.metalName, unit_price: metal.value });
        }
      });
    }

    if (currencyFeedData.length > 0) {
      const currency = currencyFeedData.find((i) => i.currency == 'USD/INR');
      if (currency) {
        data.push({
          item_name: 'USD/INR',
          unit_price: currency.value,
        });
      }
    }
    return data;
  };

  return (
    <div className='top-bands'>
      <div className='container-fluid top-bands1'>
        <div className='container'>
          <p className='top-bands1-txt '>
            Request Callback &nbsp;
            <a href='https://wa.me/+918169860012' target='_blank'>
              +91 81698 60012{' '}
              <img src='images/w-icon-hover.png' id='wa-hover' width={20} />
            </a>
          </p>
        </div>
      </div>
      <div className='container-fluid top-bands2'>
        <div className='container'>
          <p className='top-bands2-txt text-center'>
            {metalFeedData.length > 0 && currencyFeedData.length > 0
              ? convert().map((item, i, array) => (
                  <>
                    <span>{item.item_name}</span> $ {formatNo(item.unit_price)}{' '}
                    {array.length == i + 1 ? '' : ' | '}{' '}
                  </>
                ))
              : '⠀'}
          </p>
        </div>
      </div>
    </div>
  );
};

const formatNo = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default TopBands;
