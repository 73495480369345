import AxiosInstance from "./axiosConfig";

export const getMetalPrices = async (params) => {
  const response = await AxiosInstance.get(`/price/getprice`, {
    params: {
      ...params,
    },
  });
  return response;
};

export const getMetalTickerPrices = async () => {
  const response = await AxiosInstance.get('/price/getallmetaltickerfeed');
  return response;
};
// metalCode=Ag&partnerId=1&type=QTY&uom=g&value=1&currency=INR
