import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Form from '../Components/Form/Form';
import NewForm from '../Components/Form/NewFom';
import Input from '../Components/Input';
import PageLayout from '../Components/PageLayout';
import './homepage.css';
// import googleLogo from "../img/Partner_logos/google-logo.webp";
const PartnerLogos = [
  {
    src: 'HP_logo.png',
  },
  {
    src: 'google-logo.webp',
  },
  {
    src: '1896px-Razorpay_logo.svg.png',
  },
  {
    src: 'apple.logo.jpg',
  },
  {
    src: 'DBS_Bank_logo_logotype.png',
  },
  {
    src: 'erp_next_logo.png',
  },
  {
    src: 'IRIS-GST-LOGO.png',
  },
  {
    src: 'Karza_tech_logo.png',
  },
  {
    src: 'Morningstar_Logo.svg.png',
  },
  {
    src: 'veri5_logo.png',
  },
  {
    src: 'neosoft-technologies-neosoft-logo.png',
  },
];

const HomePage = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageLayout page='Main'>
      <div
        className='buy-form-container '
        style={{
          marginTop: '120px',
          maxWidth: '100%',
        }}
      >
        <div className=' buy-form-container-2 container '>
          <div className='image-container'>
            {/* <img src='images/herobanner-girl-img.png'></img> */}
          </div>
          {/* <div
            className='buy-form__heading'
            style={{ paddingBottom: '20px' }}
            data-aos='fade-right'
          >
            Ready to start <br /> investing in CoinBazaar?
          </div> */}
          <div>
            <Form />
            {/* <NewForm /> */}
          </div>
        </div>
      </div>
      <div>
        <section className='sec-1'>
          <div className='container' data-aos='fade-down'>
            <h1 className='sec-title'>
              Why invest with <span>CoinBazaar</span>
            </h1>
            <hr className='hr-class2' />
            <br></br>
            <p className='center-sec-txt'>
              CoinBazaar provides much-needed diversification and helps protect
              your investments from risk. It is a secure digital solution that
              makes purchasing and selling precious metals easier for everyone.
            </p>

            <div className='row'>
              <div className='col-md-12'>
                <div className='why-box'>
                  <div className='why-box1'>
                    <p>
                      <img src='images/why-icon1.png' />
                    </p>
                    <p>Low entry cost</p>
                    <div className='hover-txt'>
                      Own precious metals for as little as ₹100.
                    </div>
                  </div>
                  <div className='why-box1'>
                    <p>
                      <img src='images/why-icon2.png' />
                    </p>
                    <p>Asset-backed</p>
                    <div className='hover-txt'>
                      The metal you buy is fully insured and held in third-party
                      vaults
                    </div>
                  </div>
                  <div className='why-box1'>
                    <p>
                      <img src='images/why-icon3.png' />
                    </p>
                    <p>Direct ownership</p>
                    <div className='hover-txt'>
                      Pooled ownership model, with individual title and ability
                      to get physical metal
                    </div>
                  </div>
                  <div className='why-box1'>
                    <p>
                      <img src='images/why-icon4.png' />
                    </p>
                    <p>Two-way pricing</p>
                    <div className='hover-txt'>
                      Pricing is transparent and real-time based on global
                      benchmarks
                    </div>
                  </div>
                  <div className='why-box1'>
                    <p>
                      <img src='images/why-icon5.png' />
                    </p>
                    <p>Safe & Trusted</p>
                    <div className='hover-txt'>
                      Only metals from London Good Delivery brands are vaulted.
                      Insured and held in reputed 3rd party vaults
                    </div>
                  </div>
                  <div className='why-box1'>
                    <p>
                      <img src='images/why-icon6.png' />
                    </p>
                    <p>24x7</p>
                    <div className='hover-txt'>
                      Always-on platform accessible via smartphone or a laptop
                    </div>
                  </div>
                  <div className='why-box1'>
                    <p>
                      <img src='images/why-icon7.png' />
                    </p>
                    <p>Instant Liquidity</p>
                    <div className='hover-txt'>
                      Instant execution with timely and secure bank settlements
                    </div>
                  </div>
                  <div className='why-box1'>
                    <p>
                      <img
                        src='images/full-digital.png'
                        width={123}
                        height={78}
                      />
                    </p>
                    <p>100% Digital</p>
                    <div className='hover-txt'>
                      Buy physical metals completely online using your Android /
                      iOS device or desktop.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </PageLayout>
  );
};

export default HomePage;
