import axios from "axios";
import { toast } from "react-toastify";

import { BASE_URL } from "../../Constants/apiConstants";

const instance = axios.create({ baseURL: BASE_URL });

function responseHelper(response) {
  if (response?.status === 200 || response?.status === 201) {
    return response.data;
  }
  return null;
}

function errorHelper(error) {
  if (error?.response?.data) {
    const message = error.response.data;
    toast.error(message?.message);
  }
  return null;
}

instance.interceptors.response.use(responseHelper, errorHelper);

export default instance;
