import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Footer = () => {
  const history = useHistory();
  return (
    <footer className='main-footer'>
      <div
        style={{
          borderBottom: '5px solid',
          borderColor: '#CEA538',
          width: '98%',
          margin: '0 auto',
        }}
      ></div>
      <div className='container mt-3'>
        <div className='row'>
          <div className='col-md-5 col-sm-3 d-flex'>
            <p className='m-footer-logo'>
              {/* <img src='images/coin-bazzar1.png' width={'177px'} height={'45'} /> */}
          <img src='images/coin-bazzar1.png' width='177px' height='45' />

            </p>
          </div>

          <div className='col-md-4'>
            <div className='m-footer-txt1 '>
              <div>
                <img
                  src='images/phone3.png'
                  id='wa-hover'
                  width={20}
                  alt='Whatsapp Icon'
                  title='Whatsapp Icon'
                ></img>{' '}
                <a href='mailto:support@ebullion.in' style={{ color: 'black' }}>
                  +91 81698 60012
                </a>
              </div>
              <div className='mt-2'>
                <img
                  src='images/mail4.png'
                  id='wa-hover'
                  width={20}
                  alt='Whatsapp Icon'
                  title='Whatsapp Icon'
                ></img>{' '}
                <a href='mailto:support@ebullion.in' style={{ color: 'black' }}>
                  info@coinbazaar.in
                </a>
              </div>
              {/* <p className='ml-1'> info@coinbazaar.in </p> */}
            </div>
          </div>
          {/* <div className='col-md-2 col-sm-2'></div>
          <div className='col-md-2 col-sm-2'></div>

          <div className='col-md-2 col-sm-2'></div> */}
          <div className='col-md-3 col-sm-3'>
            <div className='mt-2'>
              <p className='social-icons'>
                <a
                  href='https://www.facebook.com/CoinBazaar.in'
                  target='_blank'
                  onMouseOut='MM_swapImgRestore()'
                  onMouseOver="MM_swapImage('Image1','','images/f-icon-hover.png',1)"
                >
                  <img src='images/f-icon.png' id='Image1' />
                </a>
                <a
                  href='https://twitter.com/coin_bazaar'
                  target='_blank'
                  onMouseOut='MM_swapImgRestore()'
                  onMouseOver="MM_swapImage('Image2','','images/t-icon-hover.png',2)"
                >
                  <img src='images/t-icon.png' id='Image2' />
                </a>

                <a
                  href='https://www.instagram.com/coin_bazaar/'
                  target='_blank'
                  onMouseOut='MM_swapImgRestore()'
                  onMouseOver="MM_swapImage('Image3','','images/i-icon-hover.png',3)"
                >
                  <img src='images/i-icon.png' id='Image3' />
                </a>

                {/* <a href="" target="_blank" onMouseOut="MM_swapImgRestore()" onMouseOver="MM_swapImage('Image4','','images/l-icon-hover.png',4)"><img src="images/l-icon.png" id="Image4" /></a>
              <a href="https://t.me/+919930078000" target="_blank" onMouseOut="MM_swapImgRestore()" onMouseOver="MM_swapImage('Image5','','images/tel-icon-hover.png',5)"><img src="images/tel-icon.png" id="Image5" /></a> */}
              </p>
              <br></br>
              {/* <p className='m-footer-txt1'>
                <a href='https://www.hp.co.in/' target='blank'>
                  <img src='images/hp-logo.png' />
                </a>
                <br></br>
                <br></br>&copy; {new Date().getFullYear()} ebullion.in
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
