import HomePage from './Views/HomePage';
import NewFaqPage from './Views/NewFaqPage';
import FaqPage from './Views/FaqPage';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChartComponent from '../src/Components/priceCharts/ChartComponent.jsx';

function App() {
  return (
    <>
      <ToastContainer />
      {/* <EmailForm /> */}
      <Router>
        <Switch>
          <Route exact path='/'>
            <HomePage />
          </Route>
          <Route path='/faq'>
            <NewFaqPage />
          </Route>
          <Route path='/price-charts'>
            <ChartComponent />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
