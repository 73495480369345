import React, { useState } from "react";
import TopBands from './TopBands'
import NavBar from './NavBar'
import Footer from './Footer'


const PageLayout = (props) => {
    return(
        <div >
            {/* <EmailForm /> */}
            <TopBands ></TopBands>
            <NavBar page={props.page} ></NavBar>
            {props.children}
            <Footer ></Footer>
        </div>
    );
}


export default PageLayout;