import { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import PageLayout from '../PageLayout';
// import PageHeader from 'shared/page-header';
import Chart from './Chart'; // Import Chart1 component
// import Layout from '../../wrappers/layout';
import './table.css';

const ChartComponent = () => {
  const [selectedButton, setSelectedButton] = useState(0); // Set default to 'Gold'
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(5); // Set default to '1D'

  const handleButtonClick = (buttonIndex) => {
    if (buttonIndex < 4) {
      // If it's a metal button
      setSelectedButton(buttonIndex);
    } else {
      // If it's a time frame button
      setSelectedTimeFrame(buttonIndex);
    }
  };
  const metalCodes = ['Au', 'Ag', 'Pt', 'Pd'];
  const buttonNames = [
    'Gold',
    'Silver',
    'Platinum',
    'Palladium',
    '1D',
    '1W',
    '1M',
    '3M',
    '6M',
    '1Y',
  ];
  const renderButtons = () => {
    return buttonNames.map((buttonName, index) => (
      <Button
        key={index}
        variant='light'
        className={`rounded-square ${
          selectedButton === index || selectedTimeFrame === index
            ? 'selected'
            : ''
        }`}
        onClick={() => handleButtonClick(index)}
        style={{
          backgroundColor:
            selectedButton === index || selectedTimeFrame === index
              ? '#3A3733'
              : '#fff',
          fontWeight: 'bold',
          color:
            selectedButton === index || selectedTimeFrame === index
              ? '#fff'
              : '#000',
          border: '1px solid #ccc',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          margin: '5px',
          fontSize: '14px',
        }}
      >
        {buttonName}
      </Button>
    ));
  };

  useEffect(() => {
    // Pass the selected values as props to Chart1
    // You can customize the prop names based on what Chart1 expects
    const selectedMetal = metalCodes[selectedButton];
    const selectedDuration = buttonNames[selectedTimeFrame];
  }, [selectedButton, selectedTimeFrame]);

  return (
    <PageLayout page='price-charts'>
      <header style={{ marginTop: '150px' }}>
        <section>
          <h3 className='text-center' style={{ fontWeight: '700' }}>
           Price Charts
          </h3>
          <div
            style={{
              width: '4%',
              borderBottom: '5px solid #CEA538',
              margin: '9px auto',
              marginBottom: '35px',
              borderRadius: '9px',
            }}
          ></div>
        </section>
      </header>
      <div>
        {/* <PageHeader
          mainTitle=''
          subTitle=''
          isShowSub={false}
        /> */}
        <div className='chart-table-responsive'>
          <Table striped bordered className='custom-table-chart'>
            <tbody>
              <tr>
                <td colSpan={3} className='small-width'>
                  {renderButtons()}
                </td>
              </tr>
              <tr className='white-background'>
                <td colSpan={3} className='bigger-width'>
                  {/* Pass the selected values as props to Chart1 */}
                  <Chart
                    selectedMetal={metalCodes[selectedButton]}
                    selectedDuration={buttonNames[selectedTimeFrame]}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div
          className='m-2 text-center'
          style={{ fontSize: '14px', color: '#890105' }}
        >
          *Note: Daily prices are captured at 9:00 am (IST).
        </div>
      </div>
    </PageLayout>
  );
};

export default ChartComponent;
